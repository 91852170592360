// import React from "react";
import Header from "../../Header1";
import Footer from "../../Footer1";
import Title from "./Title";
import About from "./About";
import Team from "./Team";
import Testimonial from "./Testimonial";
import Blog from "./Blog";
import PriceTable from "./PriceTable";
import Counter from "./Counter";
import AboutTwo from "./AboutTwo";

import React, { useState } from "react";
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

// https://www.npmjs.com/package/mui-datatables
// https://www.npmjs.com/package/axios
// https://stackoverflow.com/questions/70203488/how-can-i-fetch-data-from-mongodb-and-display-it-on-react-front-end

function ServiceOne() {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("600px");

  const columns = ["Job Title", "Job Description", "Job Location", "Employer", "Employer Contact"];

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive,
    download: false,
    print: false,
    tableBodyHeight,
    tableBodyMaxHeight,
    expandableRows:false,
    expandableRowsOnClick:false,
    onRowsDelete:()=>  false, //prevents delete
    selectableRows:false,//hides checkboes
    // selectableRows: "multiple",
    // selectableRowsHideCheckboxes: true,
    // selectableRowsOnClick: true,
  };

  // https://www.mockaroo.com/ used to generate sample data
  // const data = [
  //   ["Gabby George", "Business Analyst", "Minneapolis"],
  //   [
  //     "Aiden Lloyd",
  //     "Business Consultant for an International Company and CEO of Tony's Burger Palace",
  //     "Dallas"
  //   ],
  //   ["Jaden Collins", "Attorney", "Santa Ana"],
  //   ["Franky Rees", "Business Analyst", "St. Petersburg"],
  //   ["Aaren Rose", null, "Toledo"],
  //   ["Johnny Jones", "Business Analyst", "St. Petersburg"],
  //   ["Jimmy Johns", "Business Analyst", "Baltimore"],
  //   ["Jack Jackson", "Business Analyst", "El Paso"],
  //   ["Joe Jones", "Computer Programmer", "El Paso"],
  //   ["Jacky Jackson", "Business Consultant", "Baltimore"],
  //   ["Jo Jo", "Software Developer", "Washington DC"],
  //   ["Donna Marie", "Business Manager", "Annapolis"]
  // ];

  const data =[    
["Speech Pathologist","Computer Systems Analyst II","Valença do Piauí","Ledner-Gerhold", "Ledner@test.com"],
["Developer I","Geological Engineer","Zbarazh","Becker-Shanahan", "Ledner@test.com"],
["Professor","Accountant II","Laboulaye","Oberbrunner, Stracke and Emmerich", "Ledner@test.com"],
// ["Registered Nurse","Assistant Media Planner","El Nido","O'Keefe and Sons"],
// ["Engineer IV","Senior Quality Engineer","Farkhor","Little-Frami"],
// ["VP Marketing","Design Engineer","Helsingborg","Emard-Kuhn"],
// ["Database Administrator IV","Project Manager","Miguel Hidalgo","Batz and Sons"],
// ["Nuclear Power Engineer","Web Developer II","Bagorejo","Davis, Schaefer and Von"],
// ["Compensation Analyst","Developer I","Qarqaraly","Schoen Group"],
// ["VP Product Management","Engineer II","Oslo","DuBuque-Baumbach"],
// ["Recruiter","Budget/Accounting Analyst II","Svetlyy","Orn, Macejkovic and Mosciski"],
// ["Research Assistant II","Assistant Manager","Ban Bueng","Walter LLC"],
// ["Financial Analyst","Payment Adjustment Coordinator","Chamical","Klein Inc"],
// ["Design Engineer","Analyst Programmer","Al Jaghbūb","Brown Inc"],
// ["Nurse","Quality Control Specialist","Ḥurfeish","Dicki, Keeling and Dooley"],
// ["Account Representative I","Assistant Professor","Karagay","Blanda and Sons"],
// ["Analyst Programmer","Social Worker","Port Erin","Schuster, Wisoky and Conroy"],
// ["Environmental Tech","Programmer IV","Lephalale","Kling-Wyman"],
// ["General Manager","Senior Sales Associate","Lamotrek","Halvorson, Marvin and Schroeder"],
// ["Software Test Engineer IV","Senior Quality Engineer","Jinzao","McCullough-Wolf"],
// ["Tax Accountant","Office Assistant II","Dakhla","O'Kon and Sons"],
// ["Legal Assistant","Systems Administrator II","Vito","Mosciski-Buckridge"],
// ["Product Engineer","Human Resources Assistant IV","Oslo","Hauck, Jerde and Nienow"],
// ["Systems Administrator II","Senior Editor","New Iloilo","Green LLC"],
// ["General Manager","Associate Professor","Kotel’va","Beer, O'Reilly and Kozey"],
// ["Associate Professor","Quality Control Specialist","Oslo","Satterfield, D'Amore and Hamill"],
// ["VP Marketing","Assistant Professor","Louisville","Kozey, McLaughlin and Spinka"],
// ["Physical Therapy Assistant","Graphic Designer","Carvalhinho","Lesch Inc"],
// ["Graphic Designer","Compensation Analyst","Chipoka","Harber-Rogahn"],
// ["Software Consultant","Teacher","San Quintin","Robel and Sons"],
// ["Health Coach II","Clinical Specialist","Dachuan","Simonis, Homenick and McLaughlin"],
// ["Registered Nurse","Nuclear Power Engineer","Karlovo","Quitzon Inc"],
// ["Sales Associate","Clinical Specialist","Jincheng","Upton Group"],
// ["Food Chemist","VP Product Management","Jaquimeyes","Herzog, Koelpin and Jenkins"],
// ["Sales Associate","Research Associate","Pujijie","Pagac-Mosciski"],
// ["GIS Technical Architect","Operator","Evxinoúpolis","Huel, Jones and Metz"],
// ["Professor","Operator","Okunoya","Brakus-Bayer"],
// ["Computer Systems Analyst III","Design Engineer","Roubaix","Grant Group"],
// ["Database Administrator III","Speech Pathologist","Bolhrad","Bogisich and Sons"],
// ["Sales Representative","Human Resources Assistant IV","Mingfeng","Wilkinson-Kuphal"],
// ["Research Associate","Pharmacist","Kavadarci","Medhurst", "Abbott and Fisher"],
// ["Operator","Project Manager","Kanekomachi","Wolf-Welch"],
// ["Technical Writer","Pharmacist","Satrejan","Ortiz-Buckridge"],
// ["Sales Representative","Compensation Analyst","Junchuan","Kutch, Carter and Walker"],
// ["Quality Engineer","Nurse","Bolengpulau","Hayes-Koss"],
// ["Marketing Assistant","Human Resources Assistant III","Kashihara","Schowalter Group"],
// ["Project Manager","Professor","Ajaccio","Hettinger-Sporer"],
// ["Software Consultant","Mechanical Systems Engineer","Uritsk","Gleichner LLC"],
// ["Paralegal","Media Manager III","Na Kae","Mills-Green"],
// ["Executive Secretary","Physical Therapy Assistant","Vinež","McKenzie-McGlynn"],
// ["VP Sales","Data Coordiator","Palestina de los Altos","Hane, Wuckert and Tremblay"],
// ["Compensation Analyst","Nuclear Power Engineer","Napenay","Reynolds, Haley and Borer"],
// ["Community Outreach Specialist","Executive Secretary","Capayán","Hammes Inc"],
// ["Data Coordiator","Legal Assistant","Novosil’","Daugherty-Wehner"],
// ["Statistician II","Professor","Tan-Tan","Tillman-Lynch"],
// ["Account Representative III","Senior Financial Analyst","São Fidélis","Auer-Price"],
// ["Senior Sales Associate","Analyst Programmer","Sebegen","Emmerich, Mitchell and Wunsch"],
// ["Community Outreach Specialist","Assistant Manager","Tortosendo","Brakus, Jones and Emmerich"],
// ["Financial Analyst","Budget/Accounting Analyst II","Concepción","Walter-Jenkins"],
// ["Executive Secretary","Graphic Designer","Ogawa","Hand-Yundt"],
// ["Developer IV","Product Engineer","Staré Křečany","McDermott-Reichel"],
// ["Assistant Professor","Mechanical Systems Engineer","Tanlad","Ritchie, Huel and Hoeger"],
// ["Software Consultant","Staff Scientist","Toulouse","Schroeder, Hoppe and Rutherford"],
// ["Editor","Research Associate","Sochaux","Ondricka Inc"],
// ["Staff Accountant I","Computer Systems Analyst IV","El Tablón","Hagenes-Becker"],
// ["VP Quality Control","Technical Writer","Rusnė","Hills and Sons"],
// ["Account Coordinator","Design Engineer","Fushan","Lubowitz-Leannon"],
// ["Librarian","Sales Associate","Jingcheng","Spinka, Hackett and Koelpin"],
// ["Food Chemist","Pharmacist","Sidem","Weimann, Kiehn and Purdy"],
// ["Chemical Engineer","Financial Analyst","Berovo","Weber-Hickle"],
// ["Staff Accountant II","Automation Specialist I","Lysyanka","Rutherford-Gorczany"],
// ["Teacher","Nuclear Power Engineer","Jiaocha","Stehr, Nienow and Schaden"],
// ["Sales Associate","Civil Engineer","Wangping","Wolff-Tremblay"],
// ["Social Worker","Computer Systems Analyst IV","Pengchang","Langosh, Pfeffer and Walker"],
// ["Nurse Practicioner","Compensation Analyst","Oslo","Harris, Schultz and Walker"],
// ["Statistician IV","VP Quality Control","Pangal Sur","Carroll Group"],
// ["Technical Writer","Food Chemist","Tanghua","Simonis, Hilpert and Welch"],
// ["Structural Analysis Engineer","Social Worker","Roblin","Hickle, Olson and Haag"],
// ["Marketing Manager","Editor","Wonokromo","Pagac-Marquardt"],
// ["Speech Pathologist","Sales Representative","Seda","Osinski-Collins"],
// ["Media Manager IV","Administrative Assistant I","Taunan","Larkin-Leannon"],
// ["Sales Associate","Technical Writer","‘Alāqahdārī Kirān wa Munjān","Marks, Towne and Nicolas"],
// ["Accounting Assistant IV","Programmer IV","Khuzhir","Jones Inc"],
// ["Automation Specialist III","Staff Scientist","Cibungur","Reinger LLC"],
// ["Computer Systems Analyst III","Quality Control Specialist","Blois","Will and Sons"],
// ["Data Coordiator","Payment Adjustment Coordinator","Dongping","Schaefer-Baumbach"],
// ["Community Outreach Specialist","Project Manager","Būr Safājah","Erdman, Stoltenberg and Runolfsdottir"],
// ["Civil Engineer","Help Desk Technician","Santo António das Areias","Wolff, Gibson and Tillman"],
// ["Graphic Designer","Software Engineer II","Baimi","Adams LLC"],
// ["VP Marketing","Research Nurse","Zaña","Berge, Cummerata and Bode"],
// ["Associate Professor","Librarian","Néa Plágia","Graham-Hand"],
// ["Help Desk Operator","Budget/Accounting Analyst I","Muzambinho","Gislason and Sons"],
// ["Cost Accountant","Administrative Assistant IV","Alajuela","Schuster-Bruen"],
// ["Marketing Manager","Financial Advisor","Balaoang","Okuneva, Jenkins and Feeney"],
// ["Senior Sales Associate","Staff Scientist","Áyios Yeóryios","Lubowitz, Ankunding and Reinger"],
// ["Compensation Analyst","Tax Accountant","Grenoble","Roob-Roberts"],
// ["Structural Engineer","Assistant Manager","Pittsburgh","Blick Group"],
// ["Recruiter","Research Assistant II","Rujewa","Schimmel Inc"],
// ["Geological Engineer","Project Manager","Rancapeundey","Kub-Abshire"],
// ["Research Nurse","Dental Hygienist","Ban Tak","Shields, Raynor and Prosacco"]
  ];

  return (
    <React.Fragment>
    <>
			<Header />
			<Title />
			<div className="page-content">

      <div className="container">
      <MUIDataTable
        title={"Job Listings"}
        data={data}
        columns={columns}
        options={options}
      />
  </div>
    </div>
			<Footer />
		</>
      </React.Fragment>
  );
}

ReactDOM.render(<ServiceOne />, document.getElementById("root"));


export default ServiceOne;

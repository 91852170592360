import React from "react";
// import dataContact from "../data.json";
import { useForm } from "react-hook-form";
import ReactDOM from "react-dom";
// import html fro
// import employersForm from "./employersForm.html";

// var Iframe = React.createClass({     
// 	render: function() {
// 	  return(         
// 		<div>          
// 		  <iframe src={this.props.src} height={this.props.height} width={this.props.width}/>         
// 		</div>
// 	  )
// 	}
//   });

//   var perf =require('./employersForm.html');
  
//   ReactDOM.render(
// 	<Iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd6k5BFZDaTNKR2HibHnH9KZ04GsIoXv7hq3sAPFW7ca7UEGA/viewform?usp=sf_link" height="500" width="500"/>,
// 	document.getElementById('Form')
//   );

const Form = () => {
	// const { register, handleSubmit, errors } = useForm();
	// const onSubmit = (data) => {
	// 	alert(JSON.stringify(data));
	// };
	

	return (
		// <p>
		// 	TEST
		// 	<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd6k5BFZDaTNKR2HibHnH9KZ04GsIoXv7hq3sAPFW7ca7UEGA/viewform?embedded=true" width="640" height="1807" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

		// </p>

<section className="o-hidden p-0 custom-mt-10 z-index-0">
<div className="container-fluid p-0">
	<div className="row align-items-center">
		<div className="col-md-12">
			<div className="map iframe-h-2">
				<iframe
					src="https://docs.google.com/forms/d/e/1FAIpQLSd6k5BFZDaTNKR2HibHnH9KZ04GsIoXv7hq3sAPFW7ca7UEGA/viewform?embedded=true" width="640" height="220" frameborder="0" marginheight="0" marginwidth="0">
					
				</iframe>
			</div>
		</div>
	</div>
</div>
</section>
		// <iframe src={perf }></iframe> 
		// <Iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd6k5BFZDaTNKR2HibHnH9KZ04GsIoXv7hq3sAPFW7ca7UEGA/viewform?usp=sf_link" height="500" width="500"/>,
		// document.getElementById('Form')
		//<div dangerouslySetInnerHTML={{ __html: "<iframe src='https://www.youtube.com/embed/cWDJoK8zw58' />"}} />
		// <div className="google-map-code">
		// <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://docs.google.com/forms/d/e/1FAIpQLSd6k5BFZDaTNKR2HibHnH9KZ04GsIoXv7hq3sAPFW7ca7UEGA/viewform?usp=sf_link' />"}} />
						
		// 			</div>		
		// <div>
		// 	<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd6k5BFZDaTNKR2HibHnH9KZ04GsIoXv7hq3sAPFW7ca7UEGA/viewform?embedded=true" width="640" height="1807" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
		// </div>
					
	);
};

// ReactDOM.render(<Form />, document.getElementById("container"));
export default Form;

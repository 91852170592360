import React from "react";
import dataContact from "../../data.json";
import { useForm } from "react-hook-form";

const Form = () => {
	const { register, handleSubmit, errors } = useForm();
	const onSubmit = (data) => {
		alert(JSON.stringify(data));
	};

	return (
		<div className="page-content">
			<section className="contact-1">
				<div className="container">
					<div className="row">
						<div className="col-xl-6 col-lg-8 col-md-12 mr-auto">
							<div className="section-title">
								<div className="title-effect">
									<div className="bar bar-top"></div>
									<div className="bar bar-right"></div>
									<div className="bar bar-bottom"></div>
									<div className="bar bar-left"></div>
								</div>
								<h6>
									{dataContact.data.contact[0].details.title}
								</h6>
								<h2>
									{
										dataContact.data.contact[0].details
											.sub_title
									}
								</h2>
								<p>
									{
										dataContact.data.contact[0].details
											.description
									}
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<iframe 
							src="https://docs.google.com/forms/d/e/1FAIpQLScKrR2-vi8JV0588mHJGDMqWRBI1YOfoRrcC4bVsTCC1tT5AQ/viewform?embedded=true" width="640" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…
							</iframe>
						</div>
					</div>
				</div>
			</section>

			<section className="contact-info p-0 z-index-1">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-4 col-md-12">
							<div className="contact-media">
								{" "}
								<i className="flaticon-paper-plane"></i>
								<span>Address:</span>
								<p>
									{
										dataContact.data.contact[0].details
											.address
									}
								</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 md-mt-5">
							<div className="contact-media">
								{" "}
								<i className="flaticon-email"></i>
								<span>Email Address</span>
								<a
									href={`mailto:${dataContact.data.contact[0].details.email}`}
								>
									{" "}
									{dataContact.data.contact[0].details.email}
								</a>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 md-mt-5">
							<div className="contact-media">
								{" "}
								<i className="flaticon-social-media"></i>
								<span>Phone Number</span>
								<a
									href={`mailto:${dataContact.data.contact[0].details.phone}`}
								>
									{dataContact.data.contact[0].details.phone}
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			
		</div>
	);
};

export default Form;

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeOne from "./home/homeOne";
// import HomeTwo from "./home/homeTwo";
// import HomeThree from "./home/homeThree";
// import HomeFour from "./home/homeFour";
// import HomeFive from "./home/homeFive";
// import HomeSix from "./home/homeSix";
import Team from "./pages/team";
import TeamSingle from "./pages/teamSingle";
import Faq from "./pages/faq";
import Error404 from "./pages/error404";
import ComingSoon from "./pages/comingSoon";
import Accordion from "./pages/element/accordion";
import BlogPost from "./pages/element/blogPost";
import Counter from "./pages/element/counter";
import FeatureBox from "./pages/element/featureBox";
import PricingTable from "./pages/element/pricingTable";
import TeamElement from "./pages/element/team";
import Testimonial from "./pages/element/testimonial";
import AboutOne from "./about/aboutOne";
// import AboutTwo from "./about/aboutTwo";
import ServiceOne from "./services/serviceOne";
// import ServiceTwo from "./services/serviceTwo";
import ContactOne from "./contact/contactOne";
import Employers from "./employers"
// import ContactTwo from "./contact/contactTwo";
import BlogGrid from "./blogs/blogGrid";
import LeftSidebar from "./blogs/leftSidebar";
import RightSidebar from "./blogs/rightSidebar";
import BlogSingle from "./blogs/blogSingle";
import Login from "./login";
import SignUp from "./signup";
import Privacy from "./policy/privacy";
import Terms from "./policy/terms";
import LoginSuccess from "./pages/loginSuccess";
import SignupSuccess from "./pages/signupSuccess";

function MainNavigation() {
  return (
    <Router>
      <Switch>
        {/** Home */}
        <Route path="/" exact={true} component={HomeOne} />
        {/* <Route path="/home-one" exact={true} component={HomeOne} /> */}
        {/* <Route path="/home-two" exact={true} component={HomeTwo} />
        <Route path="/home-three" exact={true} component={HomeThree} />
        <Route path="/home-four" exact={true} component={HomeFour} />
        <Route path="/home-five" exact={true} component={HomeFive} />
        <Route path="/home-six" exact={true} component={HomeSix} /> */}
        {/** Pages */}
        <Route path="/faq" exact={true} component={Faq} />
        {/* <Route path="/team" exact={true} component={Team} />
        <Route path="/teamsingle" exact={true} component={TeamSingle} />
        <Route path="/faq" exact={true} component={Faq} />
        <Route path="/comingsoon" exact={true} component={ComingSoon} />
        <Route path="/loginsuccess" exact={true} component={LoginSuccess} />
        <Route path="/signupsuccess" exact={true} component={SignupSuccess} />
        <Route path="/error404" exact={true} component={Error404} /> */}
        {/** Elements */}
        {/* <Route path="/accordion" exact={true} component={Accordion} />
        <Route path="/blogpost" exact={true} component={BlogPost} />
        <Route path="/counter" exact={true} component={Counter} />
        <Route path="/featurebox" exact={true} component={FeatureBox} />
        <Route path="/pricing" exact={true} component={PricingTable} />
        <Route path="/teamelement" exact={true} component={TeamElement} />
        <Route path="/testimonial" exact={true} component={Testimonial} /> */}
        {/** About Services & Contact */}
        <Route path="/about" exact={true} component={AboutOne} />
        {/* <Route path="/about-two" exact={true} component={AboutTwo} /> */}
        <Route path="/service" exact={true} component={ServiceOne} />
        <Route path="/employers" exact={true} component={Employers} />
        {/* <Route path="/service-two" exact={true} component={ServiceTwo} /> */}
        <Route path="/contact" exact={true} component={ContactOne} />
        {/* <Route path="/contact-two" exact={true} component={ContactTwo} /> */}
        {/** Blogs */}
        <Route path="/bloggrid" exact={true} component={BlogGrid} />
        {/* <Route path="/leftsidebar" exact={true} component={LeftSidebar} /> */}
        <Route path="/rightsidebar" exact={true} component={RightSidebar} />
        {/* <Route path="/blogsingle" exact={true} component={BlogSingle} /> */}
        {/** Login & Policy */}
        <Route path="/login" exact={true} component={Login} />
        <Route path="/register" exact={true} component={SignUp} />
        <Route path="/privacy" exact={true} component={Privacy} />
        <Route path="/terms" exact={true} component={Terms} />
        {/** invalid routes redirection */}
        <Route component={Error404} />
      </Switch>
    </Router>
  );
}

export default MainNavigation;
